import React from 'react';
import { Heading, Box } from 'rebass/styled-components';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Particles from "react-particles-js"
import colors from "../../colors"


const Background2 = () => {
    return (
      <Particles
        params={require("../../media/particlesjs-config.json")}
        style={{
          position: "absolute",
          backgroundColor: colors.backgroundLanding,
          height: "100vh",
          zIndex: -1,
        }}
      />
    )
  }

const NotFoundPage = () => (
    <Layout>
        <Section.Container id="404" Background={Background2}>
            <Box width={[320, 400, 600]} m="auto">
                <Heading
                    color="white"
                    fontSize={['6rem', '9rem', '11rem']}
                    as="h1">
                    404
        </Heading>
                <Heading color="primary" fontSize={['2rem', '4rem', '4rem']} as="h2">
                    No se pudo encontrar la página 
        </Heading>
            </Box>
        </Section.Container>
    </Layout>
);

export default NotFoundPage;
